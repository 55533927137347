import { Link } from "gatsby" /* eslint-disable */
import PropTypes from "prop-types"
import React, { useContext, useState, useEffect, useRef } from 'react'
import {
  Stack,
  Flex,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  css,
  CloseButton
} from "@chakra-ui/core"

import { FaShoppingBag, FaSearch } from "react-icons/fa"
import StoreContext from '../context/store'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  });
  return quantity
}


const Header = ({ siteTitle }) => {
  const context = useContext(StoreContext)
  const { checkout } = context
  const [quantity, setQuantity] = useState(countQuantity(checkout ? checkout.lineItems : []))
  const [search, setSearch] = useState("")
  const search_ = useDisclosure();
  const searchInputRef = useRef()


  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []));
  }, [checkout]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" color="#0a0a0a" mx="2em" minH="3.25rem" alignItems="stretch" alignItems="center">
        <Flex fontWeight="700">
          <h1>
            <Link aria-label="search" className="has-text-black has-text-weight-bold" to="/">{siteTitle}</Link>
          </h1>
        </Flex>
        <Flex fontSize="1.25rem" fontWeight="900" fontColor="#363636">
          <Text p="0.5rem 0.75rem" onClick={search_.onOpen}>
            <FaSearch />
          </Text>
          <Box p="0.5rem 0.75rem" display="block" flexGrow={0} flexShrink={0} position="relative">
            <Link aria-label="cart" to="/cart" data-badge={(quantity > 0) ? "0" : null}>
              <FaShoppingBag />
            </Link>
          </Box>
        </Flex>
      </Stack>
      <Modal initialFocusRef={searchInputRef} isOpen={search_.isOpen} onClose={search_.onClose} size="xl" isCentered>
        <ModalOverlay backgroundColor="rgba(10,10,10,.86)" />
        <ModalContent backgroundColor="transparent" boxShadow="none">
          <CloseButton color="white"
            borderRadius="50%"
            position="fixed"
            right="20px"
            top="20px"
            css={css`
              &:hover {
                background-color: rgba(10,10,10,.3)
              }
            `}
            onClick={search_.onClose}
          />
          <form action="../search" method="GET">
            <InputGroup size="lg"
              css={css`
                &:focus-within div > .searchIcon{
                  color: #4a4a4a !important;
                }
              `}
            >
              <Input ref={searchInputRef} name="value" value={search} onChange={e => setSearch(e.target.value)} placeholder="Search"
                p="calc(.375em - 1px) calc(.625em - 1px)" fontFamily="Pacifico" fontStyle="normal"
              />
              <InputRightElement>
                <IconButton type="submit" icon="search" variant="outline" border={null} fontSize="1.5rem"
                  color="#dbdbdb" className="searchIcon"> Search </IconButton>
              </InputRightElement>
            </InputGroup>
            <Text color="white">ENTER ↵</Text>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
